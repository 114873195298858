import React, { useState, useEffect } from "react";
import { useForm, Controller, useFormState } from "react-hook-form";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as DocumentPicker from "expo-document-picker";

// global css
import GlobalStyles from "../../../styles/GlobalStyles";

// libs
import {
  Box,
  Grid,
  TextField,
  Stack,
  CardMedia,
  Snackbar,
  Autocomplete,
  FormControlLabel,
  Switch,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

// common
import { Alert } from "../../../components/common";

// services
import { updateProduct } from "../../../services/ProductService";
import { uploadPhoto } from "../../../services/UploadService";

// images
import empty_image from "./../../../../assets/images/empty_image.svg";

// datasets
import {
  getCategory,
  getBrand,
  getSupplier,
  getUom,
} from "../../../services/DateSetService";

const EditProducts = ({
  productData,
  uuid,
  setOpenDrawer,
  getProductList,
  setOpenSnack,
  setSnackVariant,
  setErrMsg,
}) => {
  const [switchState, setSwitchState] = useState(false);
  const defaultValues = {
    store_id: "",
    product_code: productData?.product_code || "",
    product_sku: productData?.product_sku || "",
    product_name: productData?.product_name || "",
    supplier_id: productData?.supplier?.id || "",
    category_id: productData?.category?.id || "",
    brand_id: productData?.brand?.id || "",
    uom: productData?.uom || "",
    low_stock_level: productData?.low_stock_level || "",
    price: productData?.price || "",
    product_description: productData?.product_description || "",
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    defaultValues: defaultValues,
  });

  const { dirtyFields } = useFormState({
    control,
  });

  const [openDialog, setOpenDialog] = useState(false);

  const handleCancel = () => {
    if (isDirty) {
      setOpenDialog(true);
    } else {
      setOpenDrawer(false);
      reset();
    }
  };

  const handleCloseDialog = (e) => {
    if (e.target.innerText == "Yes") {
      setOpenDrawer(false);
      reset();
    }
    setOpenDialog(false);
  };

  // submit form user login
  async function onSubmit(data) {
    setOpenLoader(true);
    let formData = {};
    formData = data;
    formData.category_id = categoryID ? categoryID : productData?.category?.id;
    formData.brand_id = brandID ? brandID : productData?.brand?.id;
    formData.supplier_id = supplierID ? supplierID : productData?.supplier?.id;
    formData.uom_units = uomID ? uomID : productData?.uom_units?.id;
    formData.store_id = await AsyncStorage.getItem("store_id");
    formData.status = productStatus ? 1 : 0;
    formData.image = formProductImage ? formProductImage : productData?.image;

    if (!uuid) {
      return;
    }

    try {
      const data = await updateProduct(uuid, formData);
      if (data.data.success == 1) {
        setSnackVariant("success");
        setErrMsg("Product updated successfully.");
        getProductList();
        setOpenDrawer(false);
        setOpenLoader(false);
      } else {
        const msg = data.data.errors;
        const updatedErrors = { ...errors };
        for (const key in msg) {
          if (msg.hasOwnProperty(key)) {
            const value = msg[key].join(" ");
            let field = key;
            updatedErrors[key] = { type: "manual", message: value };
            setError(field, {
              type: "server",
              message: value,
            });
          }
        }
      }
    } catch (error) {
      setSnackVariant("error");
      setErrMsg("Error editing product");
    } finally {
      setOpenSnack(true);
    }
  }

  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [uomList, setUom] = useState([]);

  useEffect(() => {
    getCategory().then((rs) => {
      const data = rs.data.data.map((data) => data);
      setCategories(data);
    });
  }, []);

  useEffect(() => {
    getBrand().then((rs) => {
      const data = rs.data.data.map((data) => data);
      setBrands(data);
    });
  }, []);

  useEffect(() => {
    getSupplier().then((rs) => {
      const data = rs.data.data.map((data) => data);
      setSuppliers(data);
    });
  }, []);

  useEffect(() => {
    getUom().then((rs) => {
      const data = rs.data.data.map((data) => data);
      setUom(data);
    });
  }, []);

  const [productStatus, setProductStatus] = React.useState(productData?.status);
  const handleChangeProductStatus = (event) => {
    setProductStatus(event.target.checked);
  };

  const [categoryID, setCategoryID] = React.useState("");
  const handleChangeCategoryID = (value) => {
    setCategoryID(value?.id || "");
  };

  const [brandID, setBrandID] = React.useState("");
  const handleChangeBrandID = (value) => {
    setBrandID(value.id);
  };

  const [uomID, setUomID] = React.useState("");

  const [supplierID, setSupplierID] = React.useState("");
  const handleChangeSupplierID = (value) => {
    setSupplierID(value.id);
  };

  const [productImage, setProductImage] = useState(productData?.image);
  const [formProductImage, setFormProductImage] = useState(null);

  const [openSnackAlert, setOpenSnackAlert] = useState(false);
  const [snackVariantAlert, setSnackVariantAlert] = useState("success");
  const [errMsgAlert, setErrMsgAlert] = useState("");

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackAlert(false);
  };

  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-() ]+$/;
  const ALPHA_NUMERIC_DASH_REGEX_NO_PAR = /^[a-zA-Z0-9- ]+$/;

  const pickImage = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "image/jpg, image/jpeg, image/png, image/svg, image/gif",
      multiple: false,
      copyToCacheDirectory: false,
    });
    if (result.canceled) {
      return;
    }
    if (
      result.mimeType == "image/jpeg" ||
      result.mimeType == "image/png" ||
      result.mimeType == "image/svg" ||
      result.mimeType == "image/gif"
    ) {
      setProductImage(result.uri);
      let formData = new FormData();
      formData.append("file", result.file);
      const link = await uploadPhoto(formData);
      if (link) {
        setFormProductImage(link.data.link);
      }
    } else {
      setOpenSnackAlert(true);
      setSnackVariantAlert("error");
      setErrMsgAlert("Please select valid image.");
    }
  };

  // loader
  const [openLoader, setOpenLoader] = React.useState(false);

  return (
    <Box
      sx={{ flexGrow: 1, width: "500px" }}
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2} sx={{ padding: 5 }}>
        <Grid item xs={12} sx={{ padding: 4 }}>
          <Grid container spacing={3} justifyContent="center" mb={2}>
            <Grid item xs={4}>
              <CardMedia
                name="customer_image"
                style={GlobalStyles.productImage}
                component="img"
                src={productImage ? productImage : empty_image}
                alt=""
                onClick={pickImage}
              />
            </Grid>
          </Grid>

          <Controller
            name="category_id"
            control={control}
            rules={{ required: true }}
            render={() => {
              return (
                <Autocomplete
                  options={categories}
                  getOptionLabel={(option) => option.category_name}
                  onChange={(event, values) => handleChangeCategoryID(values)}
                  defaultValue={productData?.category}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Product Category"
                      margin="normal"
                      required
                      fullWidth
                      variant="filled"
                      error={!!errors.category_id}
                      helperText={
                        errors.category_id
                          ? "Product Category is required."
                          : "(ex. Beauty Soap, Cosmetics)"
                      }
                    />
                  )}
                />
              );
            }}
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="product_name"
                rules={{
                  required: true,
                  minLength: {
                    value: 4,
                    message: "Must be 4 digits.",
                  },
                }}
                control={control}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    label="Product Name"
                    variant="filled"
                    onKeyDown={(event) => {
                      if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={() =>
                      setValue("product_name", { shouldDirty: true })
                    }
                    inputProps={{ autoComplete: "none" }}
                    error={!!errors.product_name}
                    helperText={
                      errors.product_name?.message
                        ? errors.product_name?.message
                        : errors.product_name
                        ? "Product Name is required."
                        : "(ex. Hiyas Soap, HauzPro)"
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="brand_id"
                control={control}
                rules={{ required: true }}
                render={() => {
                  return (
                    <Autocomplete
                      options={brands}
                      getOptionLabel={(option) => option.brand_name}
                      onChange={(event, values) => handleChangeBrandID(values)}
                      defaultValue={productData?.brand}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Brand"
                          margin="normal"
                          required
                          fullWidth
                          variant="filled"
                          error={!!errors.brand_id}
                          helperText={errors.brand_id && "Brand is required."}
                        />
                      )}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="product_code"
                control={control}
                rules={{
                  required: true,
                  minLength: {
                    value: 4,
                    message: "Must be 4 digits.",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    required
                    label="Product Code"
                    variant="filled"
                    onKeyDown={(event) => {
                      if (!ALPHA_NUMERIC_DASH_REGEX_NO_PAR.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{ autoComplete: "none" }}
                    error={!!errors.product_code}
                    helperText={
                      errors.product_code?.message
                        ? errors.product_code?.message
                        : errors.product_code
                        ? "Product Code is required."
                        : ""
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="product_sku"
                control={control}
                rules={{
                  minLength: {
                    value: 4,
                    message: "Must be 4 digits.",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    label="SKU"
                    variant="filled"
                    onKeyDown={(event) => {
                      if (!ALPHA_NUMERIC_DASH_REGEX_NO_PAR.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    inputProps={{ autoComplete: "none" }}
                    error={!!errors.product_sku}
                    helperText={
                      errors.product_sku
                        ? errors.product_sku?.message
                        : "(optional)"
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Controller
                name="uom"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    label="No. of Units"
                    variant="filled"
                    inputProps={{ autoComplete: "none" }}
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                    helperText="(optional)"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                margin="normal"
                fullWidth
                disabled
                label="Unit of Measurement"
                variant="filled"
                inputProps={{ autoComplete: "none" }}
                defaultValue={productData?.uom_data?.name}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                fullWidth
                required
                disabled
                label="Stock on hand"
                variant="filled"
                inputProps={{ autoComplete: "none" }}
                type="number"
                InputProps={{
                  inputProps: { min: 0 },
                }}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
                helperText="(Qty)"
                defaultValue={productData?.summary.available_stock}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="low_stock_level"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    required
                    label="Low stock level"
                    variant="filled"
                    inputProps={{ autoComplete: "none" }}
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                    error={!!errors.low_stock_level}
                    helperText={
                      errors.low_stock_level
                        ? "Low stock level is required."
                        : "(Critical level)"
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="price"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    required
                    label="Retail Price"
                    variant="filled"
                    inputProps={{ autoComplete: "none" }}
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                    error={!!errors.price}
                    helperText={
                      errors.price ? "Retail Price is required." : "(SRP)"
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                fullWidth
                disabled
                label="Cost"
                required
                variant="filled"
                inputProps={{ autoComplete: "none" }}
                type="number"
                InputProps={{
                  inputProps: { min: 0 },
                }}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
                helperText="(Price at purchase)"
                defaultValue={productData?.summary.average_cost}
              />
            </Grid>
          </Grid>

          <Controller
            name="product_description"
            control={control}
            render={({ field }) => (
              <TextField
                label="Description"
                multiline
                fullWidth
                rows={4}
                variant="filled"
                {...field}
              />
            )}
          />

          <Controller
            name="supplier_id"
            control={control}
            render={() => {
              return (
                <Autocomplete
                  options={suppliers}
                  getOptionLabel={(option) => option.supplier_name}
                  onChange={(event, values) => handleChangeSupplierID(values)}
                  defaultValue={productData?.supplier}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Supplier"
                      margin="normal"
                      fullWidth
                      variant="filled"
                      error={!!errors.supplier_id}
                      helperText={errors.supplier_id && "Supplier is required."}
                    />
                  )}
                />
              );
            }}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Switch
                    label="Status"
                    checked={productStatus}
                    onChange={handleChangeProductStatus}
                    color="success"
                  />
                }
                label={productStatus ? "Active" : "Inactive"}
                labelPlacement="start"
              />
            </Grid>
          </Grid>

          {switchState && (
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    name="no_of_items_in_package"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        margin="normal"
                        fullWidth
                        label="No of items in package"
                        variant="filled"
                        inputProps={{ autoComplete: "none" }}
                        helperText="(Items per box)"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="size"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        margin="normal"
                        fullWidth
                        label="Size"
                        variant="filled"
                        inputProps={{ autoComplete: "none" }}
                        helperText=""
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    name="stock_on_hand_2"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        margin="normal"
                        fullWidth
                        label="Stock on hand"
                        variant="filled"
                        inputProps={{ autoComplete: "none" }}
                        helperText="(Qty)"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="low_stock_level_2"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        margin="normal"
                        fullWidth
                        label="Low stock level"
                        variant="filled"
                        inputProps={{ autoComplete: "none" }}
                        helperText="Critical level"
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          <Stack
            spacing={2}
            direction="row"
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            mt={5}
          >
            <Button onClick={() => handleCancel()} variant="outlined">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="success">
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackAlert}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackVariantAlert}
          sx={{ width: "100%" }}
        >
          {errMsgAlert}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog
        open={openDialog}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"You have unsaved changes on this page."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to leave this page and discard your changes or stay on
            this page? If you leave this page, your changes will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseDialog}>
            No
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleCloseDialog}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EditProducts;
